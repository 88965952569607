exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-csr-js": () => import("./../../../src/pages/csr.js" /* webpackChunkName: "component---src-pages-csr-js" */),
  "component---src-pages-en-csr-js": () => import("./../../../src/pages/en/csr.js" /* webpackChunkName: "component---src-pages-en-csr-js" */),
  "component---src-pages-en-group-js": () => import("./../../../src/pages/en/group.js" /* webpackChunkName: "component---src-pages-en-group-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-policy-js": () => import("./../../../src/pages/en/policy.js" /* webpackChunkName: "component---src-pages-en-policy-js" */),
  "component---src-pages-en-services-js": () => import("./../../../src/pages/en/services.js" /* webpackChunkName: "component---src-pages-en-services-js" */),
  "component---src-pages-en-terms-js": () => import("./../../../src/pages/en/terms.js" /* webpackChunkName: "component---src-pages-en-terms-js" */),
  "component---src-pages-group-js": () => import("./../../../src/pages/group.js" /* webpackChunkName: "component---src-pages-group-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

